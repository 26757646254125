.section-paragraph {
    display: flex;
    justify-content: center;
    align-items: center;
    .section__text-wrapper {
        max-width: 746px;
    }
    .title-subtitle {
        display: inline;
        text-transform: none;
        @include font(32px, $font-primary--medium);
        font-weight: 500;
        color: $color-primary-black;
        line-height: 48px;
        &:first-child {
            @include font(32px, $font-primary--bold);
            font-weight: 700;
            margin-right: 7px;
        }
    }
}


@media screen and (max-width: 1172px) {
    .section-text {
        .title-subtitle {
            @include font(28px, $font-primary--bold);

            &:first-child {
                @include font(28px, $font-primary--bold);
                margin-right: 6px;
            }
        }
    }
}

@media screen and (max-width: 970px) {
    .section-paragraph {
        padding: 0 40px;
        .title-subtitle {
            @include font(28px, $font-primary--medium);
            line-height: 45px;

            &:first-child {
                @include font(28px, $font-primary--bold);
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .section-paragraph {
        padding: 0 40px;
        .title-subtitle {
            @include font(24px, $font-primary--medium);
            line-height: 40px;

            &:first-child {
                @include font(24px, $font-primary--bold);
                margin-right: 4px;
            }
        }
    }
}

@media (max-width: 650px) {
    .section-paragraph {
        padding: 0 30px;
        .title-subtitle {
            @include font(21px, $font-primary--medium);
            line-height: 33px;

            &:first-child {
                @include font(21px, $font-primary--bold);
                margin-right: 3px;
            }
        }
    }
}

@media screen and (max-width: 456px) {
    .section-paragraph {
        padding: 0 16px;
        .title-subtitle {
            @include font(16px, $font-primary--medium);
            line-height: 24px;
            
            &:first-child {
                @include font(16px, $font-primary--bold);
                margin-right: 2px;
            }
        }
    }
}