.section {
    $this: &;
    &-steps {
        padding: 120px 160px 140px 160px;

        #{$this}__content {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
        }

        @media (max-width: 1300px) {
            padding-top: 20px;
            padding-left: 100px;
            padding-right: 100px;
        }

        @media (max-width: 1100px) {
            padding-bottom: 120px;
        }

        @media (max-width: 1000px) {
            padding-left: 80px;
            padding-right: 80px;
        }

        @media (max-width: 950px) {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media (max-width: 767px) {
            padding-bottom: 100px;
        }

        @media(max-width: 270px) {
            padding-left: 24px;
            padding-right: 24px;
            width: fit-content;
        }
    }
}
