.navigation {
    &__list {
        display: flex;
        list-style: none;
        @include font(16px, $font-primary--semiBold)
    }

    @media (max-width: 1010px) {
        &__list {
            font-size: 14px;
        }
    }

    @media (max-width: 955px) {
        .navigation {
            &__list {
                display: none;
            }
        }
    }
}


@import './__item/navigation__item';