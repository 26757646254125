.accordion {
    position: relative;
    max-width: 553px;
    max-height: 210px;
    padding: 24px 20px 24px 32px;
    border: 1px solid $color-background;
    border-radius: 24px;
    background-color: $color-white;
    transition: max-height .5s ease;
    user-select: none;
    overflow: hidden;
    cursor: pointer;
    &__title {
        @include font(32px, $font-primary--bold);
        margin-bottom: 16px;
    }
    &__content {
        @include font(16px, $font-primary--regular);
        max-width: 489px;
        line-height: 25.5px;
        opacity: 1;
        transition: opacity .5s ease;
    }
    &__close {
        @include font(50px, $font-primary--regular);
        position: absolute;
        top: 13px;
        right: 20px;
        transform: rotate(45deg);
        transition: transform .5s ease;
    }

    &--closed {
        max-height: 87px;
        overflow: hidden;
        cursor: pointer;
        .accordion__content {
            opacity: 0;
        }
        .accordion__close {
            transform: rotate(0deg);
        }
    }

    & + &,
    &:first-child {
        margin-bottom: 16px;
    }

    @media (max-width: 1170px) {
        &--closed {
            max-height: max-content;
            overflow: auto;
            .accordion__content {
                opacity: 1;
            }
            .accordion__close {
                display: none;
            }
            cursor: default;
        }
    }

    @include respond-to($mobile) {
        max-width: 100%;
        .accordion__content {
            max-width: 100%;
        }
    }

    @include respond-to($watch) {
        &__title {
            font-size: 24px;
        }
        &__content {
            font-size: 14px;
            line-height: 20px;
        }

    }
}

