.input {
    @include font(20px, $font-primary--medium);
    width: 100%;
    height: 60px;
    margin-top: 8px;
    padding-bottom: 4px;
    padding-left: 16px;
    border: none;
    outline: none;
    background: transparent;
    border: 1px solid $color-background;
    transition: border-color 0.7s ease;
    color: $color-primary-black;
    border-radius: 12px;
    &__label {
        @include font(16px, $font-primary--semiBold);
        color: $color-primary-black;
    }

    &__error-text {
        @include font(12px, $font-primary--semiBold);
        opacity: 0;
        color: $color-error;
        transition: opacity .3s ease;
    }

    &--invalid {
        border-color: $color-error;
        & + .input__error-text {
            opacity: 1;
        }
    }

    &::placeholder {
        text-align: justify;
        @include font(20px, $font-primary--medium);
        color: $color-secondary-gray;
    }

    &:focus {
        transition: border-color .7s ease;
        border-color: $color-primary-black;
    }
    &:-webkit-autofill, &:-webkit-autofill:active, &:-webkit-autofill:focus, &:-webkit-autofill:hover {
        box-shadow: 0 0 0 30px #2194FF1A inset !important;
    }
}