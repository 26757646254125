
.header {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 72px;
    z-index: 3;
    transition: height 0.3s;
    transition-delay: 0.5s;
    overflow: hidden;

    &__safari-fix-white-bg {
        display: none;
        position: fixed;
        background-color: $color-white;
        right: 17px;
        height: 72px;
        width: 100%;
    }
    .burger__btn {
        display: none;
        position: fixed;
        top: 25px;
        right: 32px;
        height: 20px;
        width: 32px; 
        z-index: 5;
        .burger__btn-item {
            position: absolute;
            width: 100%;
            height: 4px;
            top: 50%;
            margin-top: -1px;
            left: 0;
            display: block;
            background-color: $color-primary-black;
            transition: 0.5s;
        }
        
        .burger__btn-item:first-child {
            top: 3px; 
        }
        .burger__btn-item:last-child {
            top: 16px; 
        }      
    }
    &__link {
        display: block;
        position: fixed;
        top: 12px;
        left: 5vw;
        z-index: 11;
    }
    &__safari-fix-height {
        display: none;
        height: calc(100% + 1px);
    }
    .image {
        &__item {
            width: 65px;
            height: 48px;
        }
    }
    .burger__btn:hover {
        cursor: pointer;
    }
    &--active {
        height: 100%;
        transition-delay: 0s;
        .burger__btn { 
            .burger__btn-item {
                opacity: 0;
                top: 50%;
            }
            .burger__btn-item:first-child {
                opacity: 1;
                transform: rotate(131deg);
            }
            .burger__btn-item:last-child {
                opacity: 1;
                top: 3px;
                transform: rotate(-131deg);
            }
        }   
        .navigation {
            background-color: $color-white;
        }
        .navigation-burger {
            &__list {
                visibility: visible;
                opacity: 1;
                transition-delay: 0.3s;
                background-color: $color-white;
            }
        }
        &.safari-fix {
            overflow: scroll;
        }
        .header__safari-fix-white-bg {
            display: block;
        }
        .header__safari-fix-height {
            display: block;
        }
    }
    

    @media (max-width: 955px) {
        justify-content: unset;
        align-items: unset;
        padding: 0 5%;
        .burger__btn {
            display: block;
        }
    }
}