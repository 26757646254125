.navigation__item {
    margin-top: 5px;
    padding-bottom: 5px;
    border-bottom: 2px solid transparent;
    transition: border-bottom 1s ease 0s;
    text-transform: uppercase;
    text-decoration: none;
    color: $color-primary-black;
    
    &:hover, &.active {
        border-bottom: 2px solid $color-primary-black;
    }
    & + & {
        margin-left: 40px;
    }
}