.section {
    $this: &;
    &-approaches {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        overflow: hidden;
        #{$this}__title-wrapper {
            max-width: 644px;
            text-align: center;
            #{$this}__title {
                line-height: 98px;
                text-transform: capitalize;
            }
        }
        #{$this}__content-wrapper {
            width: 100vw;
            svg {
                position: absolute;
                top: 15%;
                right: 0;
                width: 100%;
                height: 600px;
            }
            #{$this}__fake-block {
                width: 100%;
                height: 550px;
            }
        }
        
        svg .svg-elem-1 {
            stroke-dashoffset: 2333.6162109375px;
            stroke-dasharray: 2333.6162109375px;
            -webkit-transition: stroke-dashoffset 1.3s ease-in 0s;
                    transition: stroke-dashoffset 1.3s ease-in 0s;
        }
        
        svg.active .svg-elem-1 {
            stroke-dashoffset: 0;
        }
        
        svg .svg-elem-2 {
            stroke-dashoffset: 2498.2314453125px;
            stroke-dasharray: 2498.2314453125px;
            -webkit-transition: stroke-dashoffset 1.3s ease-in 0.12s;
                    transition: stroke-dashoffset 1.3s ease-in 0.12s;
        }
        
        svg.active .svg-elem-2 {
            stroke-dashoffset: 0;
        }
  

        @media (max-width: 1440px) {
            #{$this}__content-wrapper {
                svg {
                    left: calc(-1 * (1440px - 100%) / 2);
                    right: calc(-1 * (1440px - 100%) / 2);
                    width: 1440px;
                }
            }
        }

        @media (max-width: 1200px) {
            #{$this}__content-wrapper {
                svg {
                    top: 0;
                    left: calc(-1 * (1368px - 100%) / 2);
                    right: calc(-1 * (1368px - 100%) / 2);
                    height: 539px;
                    width: 1368px;
                }

                #{$this}__fake-block {
                    height: 300px;
                }
            }
        }
        
        @media (max-width: 1000px) {
            #{$this}__content-wrapper {
                #{$this}__fake-block {
                    height: 200px;
                }
            }
        }

        @media (max-width: 750px) {
            #{$this}__title-wrapper {
                #{$this}__title {
                    line-height: 76px;
                }
            }
        }

        @media (max-width: 630px) {
            #{$this}__content-wrapper {
                svg {
                    top: 35%;
                    left: calc(-1 * (663px - 100%) / 2);
                    right: calc(-1 * (663px - 100%) / 2);
                    height: 261px;
                    width: 663px;

                    path {
                        stroke-width: 6px;
                    }
                }
            }
        }

        @media (max-width: 500px) {
            #{$this}__content-wrapper {
                svg {
                    top: 30%;
                }
            }
        }

        @media (max-width: 500px) {
            #{$this}__title-wrapper {
                #{$this}__title {
                    line-height: 56px;
                }
            }
        }

        @media (max-width: 300px) {
            #{$this}__title-wrapper {
                #{$this}__title {
                    @include font(36px, $font-primary--extraBold);
                    line-height: 44px;
                }
            }
            #{$this}__content-wrapper {
                svg {
                    top: 10%;
                }

                #{$this}__fake-block {
                    height: 130px;
                }
            }
        }
    }
}