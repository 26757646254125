main.page-404 {
    justify-items: center;
}

.section-404 {
    margin: 80px 0 140px;
    .section__wrapper {
        justify-items: center;
    }
    .title-notFound {
        @include font(150px, $font-primary--black);
        background: linear-gradient(136.28deg, rgba(0, 41, 255, 0.9) 2.72%, rgba(3, 51, 255, 0.8873) 9.82%, rgba(12, 78, 255, 0.8656) 21.95%, rgba(26, 122, 255, 0.8376) 37.63%, rgba(46, 183, 255, 0.8046) 56.08%, rgba(49, 192, 255, 0.8) 58.64%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 16px 0;
    }
    .section__text-wrapper {
        @include font(32px, $font-primary--bold);
        color: $color-primary-black;
    }
    .section__para-wrapper {
        @include font(24px, $font-primary--semiBold);
        line-height: 36px;
        color: $color-secondary-black;
        text-align: center;
        max-width: 470px;
    }
    .section__back-link {
        @include font(16px, $font-primary--semiBold);
        text-decoration: none;
        color: $color-background;
    }
    .section__btn {
        @include font(16px, $font-primary--bold);
        display: flex;
        justify-content: center;
        align-items:center;
        padding: 12px 24px;
        margin-top: 24px;
        max-height: 48px;
        max-width: 264px;
        background-color: $color-primary-black;
        text-transform: uppercase;
        line-height: 24px;
        color: $color-background;
        border-radius: 12px;
    }
}


@media screen and (max-width: 760px) {
    .section-404 {
        margin: 50px 40px 40px;
        .section__title-wrapper {
            .title-notFound {
                font-size: 96px;
            }
        }
    }
}
@media screen and (max-width: 450px) {
    .section-404 {
        margin: 50px 16px 40px;
        .section__title-wrapper {
            .title-notFound {
                font-size: 96px;
            }
        }
        .section__para-wrapper {
            line-height: 30px;
        }
        .section__btn-wrapper {
            width: 100%;
        }
        .section__btn {
            max-width: 100%;
            width: 100%;
            height: 48px;
        }
    }
}
