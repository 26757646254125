.section {
    $this: &;
    &-sectors {
        display: flex;
        justify-content: center;
        margin: 140px 50px 140px 50px;
        #{$this}__wrapper {
            display: flex;
            padding: 32px 48px;
            background-color: $color-white;
            border: 1px solid $color-background;
            border-radius: 32px;
            max-width: 1128px;
        }
        #{$this}__left-side {
            margin-right: 150px;
        }
        #{$this}__right-side {
            display: flex;
            align-items: center;
        }
        #{$this}__title-wrapper,
        #{$this}__text-wrapper {
            justify-self: center;
        }
        #{$this}__title-wrapper {
            .title {
                @include font(40px, $font-primary--bold);
                font-size: 40px;
                color: $color-primary-black;
            }
        }
        #{$this}__text-wrapper {
            max-width: 552px;
            margin-top: 28px;
            margin-bottom: 32px;
            .paragraph {
                @include font(16px, $font-primary--regular);
                line-height: 24px;
                color: $color-secondary-black;
            }
        }
        #{$this}__graph-wrapper {
            font-size: 0;
        }
        .progress {
            &__list {
                display: flex;
                flex-direction: column;
                list-style: none;
            }
            &__item +
            .progress__item {
                margin-top: 16px;
                font-size: 0;
            }
            &__title {
                @include font(16px, $font-primary--regular);
                color: $color-primary-black;
                line-height: 24px;
                &:first-child {
                    display: block;
                    width: 90%;
                }
            }
            &__bar {
                position: relative;
                display: inline-block;
                width: 100%;
                height: 8px;
                margin-top: 8px;
                background: rgba(222, 222, 222, 1);
                border-radius: 8px;
                overflow: hidden;
            }
            &__bar:after {
                content: '';
                position: absolute;
                width: 0;
                height: 100%;
                border-radius: 8px;
                background-color: $color-background;
            }
            &__bar#tech::after {
                width: 83.3%;
                background-color: rgba(42, 217, 39, 1);
            }
            &__bar#gaming::after {
                width: 68.6%;
                background-color: rgba(241, 185, 109, 1);
            }
            &__bar#biotech::after {
                width: 47%;
                background-color: rgba(234, 109, 139, 1);
            }
            &__bar#blockchain::after {
                width: 24%;
                background-color: rgba(73, 101, 239, 1);
            }
            &__bar#art::after {
                width: 13.9%;
                background-color: rgba(120, 91, 255, 1);
            }
        }

        $timing: 1.5s;
    
        svg .svg-elem-2 {
            stroke-dashoffset: 470.02294921875px;
            stroke-dasharray: 470.02294921875px;
            -webkit-transition: stroke-dashoffset $timing ease 0s;
                    transition: stroke-dashoffset $timing ease 0s;
        }
        
        svg.active .svg-elem-2 {
            stroke-dashoffset: 940.0458984375px;
        }
        
        
        
        svg .svg-elem-4 {
            stroke-dashoffset: 400.0971374511719px;
            stroke-dasharray: 400.0971374511719px;
            -webkit-transition: stroke-dashoffset $timing ease 0s;
                    transition: stroke-dashoffset $timing ease 0s;
        }
        
        svg.active .svg-elem-4 {
            stroke-dashoffset: 800.1942749023438px;
        }
        
        
        
        svg .svg-elem-6 {
            stroke-dashoffset: 382.70166015625px;
            stroke-dasharray: 382.70166015625px;
            -webkit-transition: stroke-dashoffset $timing ease 0s;
                    transition: stroke-dashoffset $timing ease 0s;
        }
        
        svg.active .svg-elem-6 {
            stroke-dashoffset: 765.4033203125px;
        }
        
        
        
        svg .svg-elem-8 {
            stroke-dashoffset: 298.03656005859375px;
            stroke-dasharray: 298.03656005859375px;
            -webkit-transition: stroke-dashoffset $timing ease 0s;
                    transition: stroke-dashoffset $timing ease 0s;
        }
        
        svg.active .svg-elem-8 {
            stroke-dashoffset: 596.0731201171875px;
        }
        
        
        
        svg .svg-elem-10 {
            stroke-dashoffset: 126.87837219238281px;
            stroke-dasharray: 126.87837219238281px;
            -webkit-transition: stroke-dashoffset $timing ease 0s;
                    transition: stroke-dashoffset $timing ease 0s;
        }
        
        svg.active .svg-elem-10 {
            stroke-dashoffset: 253.75674438476562px;
        }
    
        @media (max-width: 1200px) {
            .section__left-side {
                margin-right: 100px;
            }
        }
        @media (max-width: 1120px) {
            .section__left-side {
                margin-right: 50px;
            }
        }
        @media (max-width: 1020px) {
            margin: 100px 24px 100px;

            .section__wrapper {
                flex-direction: column;
            }

            .section__text-wrapper {
                max-width: 587px;
            }

            #svg-graph {
                height: 210px;
                width: 210px;
            }

            .section__left-side {
                margin-right: 0;
                margin-bottom: 32px; 
            }

            .section__right-side {
                justify-content: center;
            }
        }
        @media (max-width: 500px) {
            margin: 64px 16px 64px;

            .section-sectors {
                margin-left: 16px;
                margin-right: 16px;
            }
            .section__wrapper {
                padding: 16px;
                border-radius: 16px;
            }
            .section__text-wrapper {
                max-width: 296px;
                .paragraph {
                    font-size: 14px;
                }
            }

            .progress__title {
                font-size: 14px;
                &:first-child {
                    width: 100%;
                }
            }
            .section__left-side {
                margin-right: 0;
                margin-bottom: 32px; 
            }

            .section__right-side {
                justify-content: center;
            }
        }
        @media (max-width: 290px) {
            #svg-graph {
                height: 170px;
                width: 170px;
            }
        }
        @media (max-width: 250px) {
            #svg-graph {
                height: 150px;
                width: 150px;
            }
        }
    }
}

