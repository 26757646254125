$numColumns: 12;
$gutterWidth: 15px;
  
.grid-display {
    display: grid;
    grid-template-columns: repeat($numColumns, 5fr); // Uniform columns. No need for rounded percentages to calculate widths.
    grid-gap: 0 $gutterWidth; // Actual gutter width. No need for negative margin hack.
}

[class^=col-]{
    grid-column-end: span $numColumns; //Full width fallback when no column size is define for the screen size.
}

// NESTED LOOP
// Check out the compiled css. It is very repetitive, similar to Bootstrap Grid code
// Loop through responsive breakpoints
@each $size, $abbr in (0,xs),(768px,sm),(992px,md),(1200px,lg){
    @media (min-width: $size){
        // Loop through col classes
        @for $i from 1 through $numColumns{
            .col-#{$abbr}-#{$i}{
                grid-column-end: span $i;
            }
        }
    }
}