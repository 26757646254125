.slide {
    $this: &;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
    padding: 0 40px;
    width: 100%;
    will-change: unset;
    transition: width .3s ease;

    & > * + * {
        margin-bottom: 16px;
    }

    &__title {
        margin-bottom: 16px;
        opacity: 0;
    }

    &__icon {
        opacity: 0;
        user-select: none;
        .image__item {
            transition: width .2s ease-in, height .2s ease-in;
            width: 180px;
            height: 180px;
        }
    }
    &__subtitle {
        opacity: 0;
    }

    &__paragraph {
        max-width: 568px;
        text-align: center;
        line-height: 24px;
        opacity: 0;
    }

    &__CTA {
        @include font(16px, $font-primary--bold);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        opacity: 0;
        border-radius: 12px;
        width: 159px;
        border: 1px solid transparent;
        background-color: $color-white;
        text-transform: uppercase;
        text-decoration: none;
        color: $color-primary-black;
        cursor: pointer;
        transition:  
                    background-color .5s ease,
                    color .5s ease;

        &:hover {
            border: 1px solid $color-white;
            color: $color-white;
            background-color: transparent;
        }
    }
    &__vertical {
        display: none;
        .slide__subtitle {
            writing-mode: vertical-lr;
            transform: rotate(180deg);
            margin-bottom: 16px;
        }
    }

    &__mouse {
        position: absolute;
        bottom: 24px;
        margin-bottom: 0;
        path {
            animation: anim 1.1s ease-in-out infinite;
        }
        
        @keyframes anim {
            0% {
                transform: translateY(0)
            }
            50% {
                transform: translateY(10px)
            }
            100% {
                transform: translateY(0)
            }
        }
    }

    &--closed {
        width: 125px;
        cursor: pointer;
        
        #{$this}__title {
            display: none;
        }
        #{$this}__subtitle {
            display: none;
            writing-mode: vertical-lr;
            transform: rotate(180deg);
        }

        #{$this}__vertical {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 206px 0 40px;
            width: 100%;
            height: 100%;
            margin-bottom: 0;
            .slide__subtitle {
                display: block;
                margin-bottom: 0;
            }
            .slide__icon {
                display: block;
            }
        }

        .slide__mouse {
            display: none;
        }

        #{$this}__paragraph {
            display: none;
        }
        #{$this}__CTA {
            display: none;
        }
        
        #{$this}__icon {
            display: none;
            .image__item {
                width: 56px;
                height: 56px;
            }
        }

        &:hover {
            #{$this}__vertical {
                .slide__icon {
                    .image__item {
                        width: 80px;
                        height: 80px;
                    }
                }
            }
        }
    }

    @media (max-width: 955px) {
        transition: height .4s ease;
        height: 584px;
        justify-content: flex-start;
        padding: 50px 40px;

        .slide__mouse {
            display: none;
        }
        &--closed {
            width: 100%;
            height: 92px;
            padding: 0;
            .slide__vertical {
                padding: 24px 0;
                justify-content: center;
                .slide__icon {
                    display: none;
                }
                .slide__subtitle {
                    writing-mode: initial;
                    transform: rotate(0deg);
                    font-size: 32px;
                }
            }
        }
    }


    @media (max-width: 420px) and (min-width: 410px) {
        &:not(.slide--closed) {
            height: 471px;
        }
        
    }
    @media (max-width: 845px) and (min-width: 840px) {
        .slide__CTA {
            width: 169px;
        }
    }
    @media (max-width: 500px) {
        height: 430px;
        
        &__title {
            font-size: 24px;
        }
        &__icon {
            .image__item {
                width: 88px;
                height: 88px;
            }
        }
        &__paragraph {
            max-width: 328px;
            font-size: 14px;
            line-height: 20px;
        }
        padding: 26px 20px;
        .slide__CTA {
            width: 90%;
            font-size: 14px;
        }
        &--closed {
            padding: 0;
            height: 56px;
            .slide__vertical {
                padding: 24px 0;
                justify-content: center;
                .slide__icon {
                    display: none;
                }
                .slide__subtitle {
                    writing-mode: initial;
                    transform: rotate(0deg);
                    font-size: 32px;
                }
            }
        }
    }

    @media (max-width: 400px) {
        padding: 26px 16px;
        justify-content: flex-start;
        .slide__subtitle {
            font-size: 16px;
        }
        &--closed {
            padding: 0;
            .slide__vertical {
                .slide__subtitle {
                    font-size: 16px;
                }
            }
        }
    }


    @keyframes fade-in {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      
    @keyframes fade-out {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    
    &.animated-fade-in {
        .slide__title,
        .slide__icon,
        .slide__subtitle,
        .slide__paragraph,
        .slide__CTA {
            animation: fade-in 1s .2s ease forwards !important;
        }
    }
}

@keyframes closing {
    0% {
        width: clamp(1000px, 74%, 1920px);
    }
    100% {
        width: 125px;
    }
}
@keyframes opening {
    0% {
        width: 125px;
    }
    100% {
        width: clamp(1000px, 74%, 1920px);
    }
}
.animated-open {
    animation: opening 1s ease forwards;
}
.animated-close {
    animation: closing 1s ease forwards;
}