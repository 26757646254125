$watch: '(max-width: 360px)';
$mobile: '(max-width: 768px)';
$tablet: '(max-width: 1279px)';
$laptop: '(max-width: 1439px)';
$desktop: '(min-width: 1440px)';


@mixin respond-to($media) {
    @media #{$media} {
        @content;
    }
}


@mixin font($size, $family) {
    font-style: normal;
    @include font-size($size);
    font-family: $family;
}

@mixin on-safari {

    // Safari (7.1+)
    _::-webkit-full-page-media,
    _:future,
    :root .safari_only {
        @content;
    }

    // Safari (from 6.1 to 10.0)
    @media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio: 0) {
        @content;
    }

    // Safari (10.1+)
    @media not all and (min-resolution: .001dpcm) {
        @content;
    }
}
