.footer {
    display: flex;
    justify-content: center;
    
    color: $color-primary-black;
    background-color: $color-white;
    padding: 40px 40px;

    border-top: 1px solid #DEDEDE;

    &__wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 1128px;
        width: 100%;
    }
    
    &__title {
        @include font(24px, $font-primary--bold);
        display: block;
        margin-bottom: 24px;
        text-transform: uppercase;
    }

    &__logo-block {
        .image__item {
            max-width: 170px;
            max-height: 125px;
        }
    }
    
    &__link {
        display: block;
        margin-bottom: 40px;
    }

    &__copy {
        text-transform: uppercase;
        @include font(16px, $font-primary--semiBold);
    }
    &__nav-block {
        flex-basis: 40%;

        .navigation__list {
            flex-direction: column;
            flex-wrap: wrap;
            height: 80px;
        }
        .navigation-burger__list {
            display: none;
        }
        .navigation__item {
            margin-left: 0;
            border-bottom: none;
            width: max-content;
            padding-bottom: 0;

            span {
                border-bottom: 2px solid transparent;
                transition: border-bottom 1s ease 0s;

                &:hover {
                    border-bottom: 2px solid $color-primary-black;
                }
            }
        }

        .navigation__item:nth-of-type(2n) {
            margin-top: 22px;
        }

        .navigation__item:nth-of-type(1),
        .navigation__item:nth-of-type(2) {
            margin-right: 40px;
        }
    }

    &__contacts-block {
        display: flex;
        flex-direction: column;

        .footer__title {
            margin-bottom: 16px;
        }

    }

    .contacts__mail {
        @include font(16px, $font-primary--medium);
        text-decoration-line: underline;
        color: $color-primary-black;
    }
    .footer__socials {
        display: flex;
        .socials__item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 38px;
        }
        .image {
            width: 32px;
            height: 38px;
        }
        .image img {
            width: 32px;
            height: 38px;
        }
    }

    @media (max-width: 1200px) {
        flex-wrap: wrap;
        padding: 32px 40px 26px;

        &__logo-block {
            order: 1;
            flex-basis: 50%;

            .image__item {
                max-width: 122px;
                max-height: 90px;
            }
        }

        &__link {
            margin-bottom: 25px;
        }

        &__nav-block {
            order: 3;
            flex-basis: 100%;
            padding-top: 64px;

            .navigation__list {
                height: 90px;
            }

            .navigation__item {
                margin-right: auto;
            }

            .navigation__item:nth-of-type(2n) {
                margin-top: 28px;
            }

            .navigation__item:nth-of-type(n+3) {
                margin-left: 20px;
                margin-right: 0;
            }
            
            .navigation__text {
                @include font(16px, $font-primary--semiBold);
                line-height: 24px;
            }
        }

        &__contacts-block {
            order: 2;
            flex-basis: 50%;
        }
    }

    @media (max-width: 1020px) {
        &__wrapper {
            max-width: 658px;
        }
    }

    @include respond-to($mobile) {
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 0 24px 32px;

        &::before {
            content: '';
            width: 100%;
            order: 1;
        }
    
        &__wrapper > div:nth-child(2) {
            order: 1;
        }
        &__wrapper > div:nth-child(3) {
            order: 2;
        }

        &__logo-block {
            flex-basis: 100%;
        }

        &__link {
            margin-bottom: 16px;
        }

        &__copy {
            @include font(12px, $font-primary--semiBold);
            line-height: 16px;
        }

        &__nav-block {
            width: 100%;

            .navigation__list {
                height: initial;
            }

            .navigation__item {
                width: 100%;
            }

            .navigation__item:nth-of-type(n+3) {
                margin-left: 0px;
                margin-top: 32px;
            }

            .navigation__text {
                @include font(16px, $font-primary--semiBold);
                line-height: 24px;
            }
        }

        &__mail {
            width: 100%;
        }

        &__title {
            @include font(24px, $font-primary--bold);
            line-height: 32px;
            margin-bottom: 24px;
        }
        &__wrapper > div {
            padding-top: 40px;
        }
    }

    @media (max-width: 620px) {
        padding: 0 24px 32px;
    }

    @media (max-width: 500px) {
        justify-content: center;

        &__wrapper {
            max-width: 400px;
        }
    }

    @include respond-to($watch) {
        padding: 32px 14px;
        .footer {
            &__logo-block {
                padding-top: 0;
                .image__item {
                    max-width: 76px;
                    max-height: 56px;
                }
            }
            &__link {
                margin-bottom: 16px;
            }
        }
    }
   
    @media screen and (max-width: 1000px) {
        .footer__nav-block {
            .navigation {
                .navigation__list {
                    display: flex;
                }
    
            }
            .navigation-burger__btn {
                display: none;
            }
        }
    }
}
