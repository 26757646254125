.slider {
    height: calc(100vh - 72px);
    &__wrapper {
        display: flex;
        height: 100%;
    }
    .slide:nth-child(1) {
        background-color: $color-slide-art;

    }
    .slide:nth-child(2) {
        background-color: $color-slide-health;
    }
    .slide:nth-child(3) {
        background-color: $color-slide-science;
    }
    .slide:nth-child(4) {
        background-color: $color-slide-technology;
    }

    @media (max-width: 955px) {
        height: 859px;
        &__wrapper {
            flex-direction: column;
        }
    }
    @media (max-width: 500px) {
        height: 600px;
    }

    @media (max-width: 415px) and (min-width: 410px) {
        height: 639px;
    }
}

@import './slide/slide';