.section {
    $this: &;
    &-strategies {
        display: flex;
        justify-content: center;
        margin: 0 40px 80px;

        #{$this}__wrapper {
            max-width: 1128px;
            position: relative;
        }

        #{$this}__main-title-wrapper {
            margin-bottom: 1em;

            #{$this}__main-title {
                @include font(40px, $font-primary--extraBold);
                color: $color-primary-black;
                line-height: 56px;
                text-transform: uppercase;
            }
        }

        #{$this}__main-paragraph-wrapper {
            #{$this}__main-paragraph{
                @include font(24px, $font-primary--regular);
                line-height: 36px;
                color: $color-thirdly-black;
            }
        }

        #{$this}__left-side {
            flex: 1;
            margin-right: calc(50% + 24px);
        }

        #{$this}__strategy {
            border: 1px solid #DEDEDE;
            border-radius: 24px;
            padding: 24px;
            margin-bottom: 24px;

            &:last-of-type {
                margin-bottom: 1px;
            }

            &--main {
                .image {
                    height: 206px;
                    margin-bottom: 12px;

                    img {
                        height: 206px;
                    }
                }

                #{$this}__main-paragraph-wrapper {
                    margin-bottom: 32px;
                    min-height: 216px;
                }
            }

            #{$this}__strategy-title {
                @include font(32px, $font-primary--bold);
                color: $color-primary-black;
                line-height: 39px;
                margin-bottom: 16px;
            }

            #{$this}__strategy-paragraph {
                @include font(16px, $font-primary--regular);
                color: $color-secondary-black;
                line-height: 26px;
            }
        }

        #{$this}__contact-button {
            align-items: center;
            background: linear-gradient(263.94deg, #0029FF 0%, #31C0FF 100%);
            border-radius: 12px;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            text-transform: uppercase;
            text-decoration: none;
            gap: 10px;
            height: 48px;
            text-align: center;
            justify-content: center;
            padding: 12px 24px;
            transition: all 0.08s;
            width: 100%;

            &:hover {
                opacity: 0.9;
            }

            &:active {
                opacity: 0.8;
            }

            span {
                @include font(16px, $font-primary--bold);
                color: #fff;
                line-height: 24px;
                user-select: none;
            }
        }

        #{$this}__right-side {
            bottom: 0;
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow-x: hidden;
            overflow-y: auto;
            position: absolute;
            right: 0;
            top: 0;
            width: 50%;
            scrollbar-width: none;   

            &::-webkit-scrollbar {
                display: none;
            }
        }

        #{$this}__gradient {
            height: 40px;
            position: absolute;
            right: 0;
            width: 50%;

            &--top {
                background-image: linear-gradient(to top, transparent, white);
                top: 0;
            }

            &--bottom {
                background-image: linear-gradient(to bottom, transparent, white);
                bottom: -1px;
            }

            &--not-visible {
                display: none;
            }
        }


        @media (max-width: 1200px) {
            margin: 0 40px 40px;

            #{$this}__strategy {
                border-radius: 16px;
            }
        }

        @media (max-width: 1100px) {
            margin-bottom: 60px;

            #{$this}__left-side {
                .image {
                    height: 120px;

                    img {
                        height: 120px;
                    }
                }
            }

            #{$this}__strategy {
                padding: 16px;

                #{$this}__strategy-title {
                    @include font(20px, $font-primary--bold);
                    line-height: 24.38px;
                    color: $color-primary-black;
                }

                #{$this}__strategy-paragraph {
                    @include font(16px, $font-primary--regular);
                    line-height: 22.4px;
                    color: $color-secondary-black;
                }

                &:not(&:last-of-type) {
                    margin-bottom: 16px;
                }
            }
        }

        @media (max-width: 1020px) {
            margin: 0 calc((100% - 685px) / 2) 60px;
        }

        @media (max-width: 900px) {
            #{$this}__main-title-wrapper {
                margin-bottom: 12px;
                
                #{$this}__main-title {
                    @include font(24px, $font-primary--extraBold);
                    line-height: 32px;
                }
            }

            #{$this}__main-paragraph-wrapper {
                min-height: initial;

                #{$this}__main-paragraph {
                    @include font(16px, $font-primary--regular);
                    line-height: 24px;
                }
            }
        }

        @media (max-width: 767px) {
            margin: 0 24px 40px 24px;

            #{$this}__wrapper {
                flex-direction: column;
                max-width: 685px;
            }

            #{$this}__left-side {
                align-items: center;
                display: flex;
                flex-direction: column;
                margin-bottom: 16px;
                margin-right: 0;
                width: 100%;

                #{$this}__main-title-wrapper {
                    margin-bottom: 8px;

                    #{$this}__main-title {
                        @include font(24px, $font-primary--extraBold);
                        line-height: 32px;
                    }
                }

                #{$this}__main-paragraph-wrapper {
                    margin-bottom: 24px;
                    min-height: initial;

                    #{$this}__main-paragraph {
                        @include font(16px, $font-primary--regular);
                        line-height: 24px;
                    }
                }
            }

            #{$this}__strategy {
                border-radius: 12px;
                width: 100%;

                #{$this}__strategy-title {
                    @include font(20px, $font-primary--extraBold);
                    line-height: 24.38px;
                }
            }

            #{$this}__contact-button {
                span {
                    @include font(14px, $font-primary--bold);
                    line-height: 24px;
                }
            }

            #{$this}__right-side {
                position: static;
                overflow: initial;
                align-items: center;
                width: 100%;
            }

            #{$this}__gradient {
                display: none;
            }
        }

        @media (max-width: 500px) {
            #{$this}__wrapper {
                max-width: 400px;
            }
        }

        @media (max-width: 450px) {
            margin-left: 16px;
            margin-right: 16px;
        }
    }
}
