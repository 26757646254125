// =======================
// Montserrat - 400, 500, 600, 700
// =======================


@font-face {
    font-family: 'Montserrat-Regular';
    src: url('../fonts/Montserrat/Regular/Montserrat-Regular.woff2') format('woff2'),
         url('../fonts/Montserrat/Regular/Montserrat-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: url('../fonts/Montserrat/Medium/Montserrat-Medium.woff2') format('woff2'),
         url('../fonts/Montserrat/Medium/Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('../fonts/Montserrat/SemiBold/Montserrat-SemiBold.woff2') format('woff2'),
         url('../fonts/Montserrat/SemiBold/Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url('../fonts/Montserrat/Bold/Montserrat-Bold.woff2') format('woff2'),
         url('../fonts/Montserrat/Bold/Montserrat-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-ExtraBold';
    src: url('../fonts/Montserrat/ExtraBold/Montserrat-ExtraBold.woff2') format('woff2'),
         url('../fonts/Montserrat/ExtraBold/Montserrat-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Black';
    src: url('../fonts/Montserrat/Black/Montserrat-Black.woff2') format('woff2'),
         url('../fonts/Montserrat/Black/Montserrat-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

// =======================
// Inter - 400, 600
// =======================

@font-face {
    font-family: 'Inter-Regular';
    src: url('../fonts/Inter/Regular/Inter-Regular.woff2') format('woff2'),
         url('../fonts/Inter/Regular/Inter-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
  
@font-face {
    font-family: 'Inter-SemiBold';
    src: url('../fonts/Inter/SemiBold/Inter-SemiBold.woff2') format('woff2'),
         url('../fonts/Inter/SemiBold/Inter-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}



  
  



  
  
  
  
  