$square-color: rgba(237, 237, 237, .7);
$square-size: 51px 57px;
$border-size: 1px;

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
}


body {
    padding-top: 72px;
    overflow-x: hidden;
    .additional__wrapper {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 4000px;
        background-color: white;
        &.show {
            display: block;
        }
    }
}

.page {
    &-net {
        background-color: $color-white;
        background-image: 
            linear-gradient($square-color $border-size, transparent $border-size),
            linear-gradient(90deg, $square-color $border-size, transparent $border-size);
        background-size: 
            $square-size,
            $square-size;   
    }
}

.section {
    &-net {
        padding: 140px 160px;

        background-color: $color-white;
        background-image: 
            linear-gradient($square-color $border-size, transparent $border-size),
            linear-gradient(90deg, $square-color $border-size, transparent $border-size);
        background-size: 
            $square-size,
            $square-size;   
    }

    &-black {
        background-color: $color-primary-black;
        padding: 93px 150px 180px;
    }

    &__row {
        display: flex;
        &--reversed {
            flex-direction: row-reverse;
        }
    }
}

.title {
    &-main {
        @include font(80px, $font-primary--extraBold);
        text-transform: uppercase;
        color: $color-primary-black;
    }
    &-black {
        @include font(40px, $font-primary--black);
        color: $color-white;
    }
    &-subtitle {
        @include font(32px, $font-primary--extraBold);
        text-transform: uppercase;
        color: $color-white;
    }
}

.paragraph {
    @include font(16px, $font-primary--medium);
    color: $color-white;
    
    &-black {
        @include font(24px, $font-primary--medium);
        color: $color-secondary-black;
    }
}