.section-contact {
    padding: 140px 156px;
    .section__title-wrapper {
        margin-bottom: 50px;
    }

    .form {
        display: flex;
        flex-direction: column;
    }
    .section__video video {
        width: 360px;
        height: 360px;
        max-width: 100%;
        position: relative;
        top: -25px;
    }
    .row {
        display: flex;
        .input__label {
            max-width: 360px;
            &:first-child {
                margin-right: 24px;
            }
        }
    }

    #textarea-label {
        margin-top: 40px;
        max-width: 744px;
    }
    .input {
        &[name="Message"] {
            padding-top: 16px;
            resize: none;
            height: 116px;
        }
    }

    .section__photo-wrapper {
        display: flex;
        justify-content: center;

        video {
            object-fit: contain;
        }
    }
    .submit {
        @include font(16px, $font-primary--bold);
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 163px;
        width: 100%;
        margin-top: 15px;
        padding: 12px 0;
        border: 1px solid transparent;
        outline: none;
        text-transform: uppercase;
        border-radius: 12px;
        color: $color-white;
        cursor: pointer;
        background-color: $color-primary-black;
        transition: background-color .3s ease;
        &:hover {
            background-color: $color-secondary-black;
        }
    
        &--disabled {
            pointer-events: none;
            cursor: default;
        }
    }
    .spinner {
        width: 24px;
        height: 24px;
        border-bottom: 2px solid $color-white;
        border-left: 2px solid rgba(255, 255, 255, 0.9);
        border-right: 1px solid transparent;
        border-radius: 50%;
        animation: spin 1.4s infinite linear;
    }
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(-360deg);
        }
    }
    .toast {
        position: fixed;
        bottom: 5vh;
        right: -150vw;
        // right: 3vw;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 470px;
        padding: 20px;
        background: #FFFFFF;
        box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.2);
        transition: right ease-in-out .7s;

        &__title {
            max-width: 376px;
            margin-bottom: 5px;
            @include font(16px, $font-primary--semiBold);
            color: rgba(102, 187, 106, 1);
        }
        &__content {
            @include font(14px, $font-secondary--regular);
            line-height: 24px;
            letter-spacing: 0.6px;
            color: $color-primary-black;
            span {
                @include font(14px, $font-secondary--semiBold);
            }
        }
        &__close {
            position: absolute;
            width: 50px;
            height: 50px;
            top: 0;
            right: 0;
            pointer-events: auto;
            cursor: pointer;
            z-index: 5;
            div {
                margin: auto;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                width: 24px;
                height: 12px;
            }
            span {
                position: absolute;
                display: block;
                width: 100%;
                height: 2px;
                background-color: $color-secondary-gray;
                border-radius: 1px;
        
                &:first-of-type {
                    transform: rotate(45deg);
                    top: 7px;
                }
                &:last-of-type {
                    transform: rotate(-45deg);
                    bottom: 3px;
                }
            }
        }
        &__progress {
            position: absolute;
            height: 2px;
            width: 100%;
            bottom: 0;
            left: 0;
            background-color: rgba(102, 187, 106, 1);
        }
        &.active {
            right: 3vw;
        }
    }
    
    @media (max-width: 1260px) {
        padding: 140px 100px;
    }
    @media (max-width: 1100px) {
        padding: 120px 70px;
    }
    @media (max-width: 1000px) {
        padding: 100px 50px;
    }
    @media (max-width: 991px) {
        .section__photo-wrapper {
            display: none;
        }
        .row {
            flex-direction: column;
            .input__label {
                margin-top: 12px;
                max-width: 100%;
                &:first-child {
                    margin-right: 0;
                }
            }
        }
        #textarea-label {
            margin-top: 12px;
            margin-right: 0;
            max-width: 100%;
        }
        .submit {
            max-width: 100%;
            height: 48px;
        }
    }
    @media (max-width: 768px) {
        .section__title-wrapper {
            .title {
                text-align: center;
                font-size: 64px;
            }
        }
    }
    @media (max-width: 538px) {
        .section__title-wrapper {
            .title {
                font-size: 50px;
            }
        }
    }
    @media (max-width: 538px) {
        padding: 40px 16px;
    }
    @media (max-width: 443px) {
        .section__title-wrapper {
            .title {
                font-size: 45px;
            }
        }
    }
    @media (max-width: 490px) {
        .toast {
            width: 328px;
            height: 144px;
            &__title {
                font-size: 16px;
            }
            &__content {
                font-size: 12px;
                span {
                    display: block;
                }
            }
            &.active {
                right: 4vw;
            }
        }
    }
    @media (max-width: 407px) {
        .section__title-wrapper {
            .title {
                font-size: 40px;
            }
        }
    }
}
