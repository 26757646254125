// ===============
//  Colours
// ===============

$color-white: rgba(255, 255, 255, 1);

$color-background: rgba(222, 222, 222, 1);

$color-primary-black: rgba(23, 23, 23, 1);
$color-secondary-black: rgba(60, 60, 60, 1);
$color-thirdly-black: rgba(87, 87, 87, 1);

$color-paragraph-primary: rgba(31, 31, 31, 1);
$color-paragraph-secondary: rgba(87, 87, 87, 1);

$color-primary-gray: rgba(211, 211, 211, 1);
$color-secondary-gray: rgba(181, 181, 181, 1);
$color-thirdly-gray: rgba(132, 132, 132, 1);

$color-accent: rgba(54, 11, 137, 1);
$color-error: rgba(254, 0, 0, 1);
// * slides colours

$color-slide-art: rgba(68, 178, 84, 1);
$color-slide-health: rgba(49, 192, 255, 1);
$color-slide-science: rgba(0, 41, 255, 1);
$color-slide-technology: #1b1d54;


// ===============
//  end Colours
// ===============

// ===============
//  Fonts
// ===============

// Montserrat ( Main )

$font-primary--black: 'Montserrat-Black', sans-serif;   // 900
$font-primary--extraBold: 'Montserrat-ExtraBold', sans-serif;   // 800
$font-primary--bold: 'Montserrat-Bold', sans-serif;     // 700
$font-primary--semiBold: 'Montserrat-SemiBold', sans-serif;     // 600
$font-primary--medium: 'Montserrat-Medium', sans-serif;     // 500
$font-primary--regular: 'Montserrat-Regular', sans-serif;   // 400

// Rubik ( Additional )

$font-secondary--regular: 'Inter-Regular', sans-serif;  // 400
$font-secondary--semiBold: 'Inter-SemiBold', sans-serif;  // 600

// ===============
//  end Fonts
// ===============