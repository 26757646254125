.navigation-burger {
    &__btn {
        display: none;
        position: relative;
        width: 50px;
        height: 50px;
        margin-right: 32px;
        pointer-events: auto;
        cursor: pointer;
        z-index: 11;
        div {
            margin: auto;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: 32px;
            height: 12px;
        }
        span {
            position: absolute;
            display: block;
            width: 100%;
            height: 4px;
            background-color: $color-primary-black;
            border-radius: 1px;
            transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
    
            &:first-of-type {
                top: 0;
            }
            &:last-of-type {
                bottom: 0;
            }
        }
        &.active {
            span {
                background-color: $color-primary-black;
                &:first-of-type {
                    transform: rotate(45deg);
                    top: 5px;
                }
                &:last-of-type {
                    transform: rotate(-45deg);
                    bottom: 3px;
                }
            }
        }
    }
    
    &__list {
        display: none;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 76%;
        padding: 0 10%;
        list-style: none;
        transition: opacity .3s, visibility .1s;
        visibility: hidden;
        transition-delay: .3s;
        opacity: 0;
        a.navigation__item {
            @include font(32px, $font-primary--semiBold);
            color: $color-primary-black;
            margin-left: 0;
            border-bottom: none;
            &:hover {
                border-bottom: none;
            }
        }
        .navigation__item + .navigation__item {
            margin-top: 80px;
        }
        .navigation__text {
            @include font(32px, $font-primary--semiBold);
            line-height: 39px;
        }
    }
}

@media screen and (max-width: 452px) {
    .navigation-burger {
        &__list {
            .navigation__item .navigation__text {
                font-size: 20px;
            }
            .navigation__item + .navigation__item {
                margin-top: 25px;
            }
        }
    }
}


@media screen and (max-width: 955px) {
    .navigation-burger {
        &__list {
            display: flex;
            justify-content: unset;
            margin-top: 100px;
            padding: 0;
        }
    }
}
