.logo {
    &__link {
        width: 65px;
        height: 48px;
    }
    &__svg {
        width: inherit;
        height: inherit;
    }
}
