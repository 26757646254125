.step {
    $this: &;

    display: flex;
    margin-bottom: 8px;
    max-width: 1128px;

    &--mobile {
        &__image {
            display: none;
            width: 100%;
            
            img {
                object-fit: contain;
                max-height: 300px;
                width: 100%;
            }
        }
    }

    &__leftSide {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-right: 108px;
        padding-bottom: 80px;
        width: 50%;
    }

    &__image {
        position: absolute;
        top: 0;
        .image {
            width: 100%;
        }
        
        img {
            // transition: opacity .5s ease;
            opacity: 0;
            object-fit: contain;
            max-height: 297px;
            width: 100%;
        }
        &.image--visible img {
            opacity: 1;
        }

        &.image--position--unset{
            position: unset;
        }
    }

    &__centerSide {
        width: 0;
        position: relative;
    }

    &__lineWrapper {
        position: absolute;
        top: 0;
        left: 50%;
        bottom: 0;
    }

    &__line {
        position: relative;
        top: 8px;
        height: calc(100% - 55px);
    }

    &__index {
        align-items: center;
        border-radius: 50%;
        display: flex;
        height: 48px;
        justify-content: center;
        position: relative;
        transform: translateX(-50%);
        width: 48px;

        span {
            @include font(24px, $font-primary--bold);
            line-height: 26px;
        }

        &[data-aos="index-activation"] {
            background-color: $color-background;
            transition-property: background-color color;
            color: $color-secondary-black;
        }
    }

    &__line line {
        stroke: $color-primary-black;
        stroke-width: 1px;
        stroke-dasharray: 11 9;
    }

    &__rightSide {
        padding-left: 108px;
        padding-bottom: 80px;
        position: relative;
        width: 50%;
    }

    &__title {
        @include font(32px, $font-primary--extraBold);
        line-height: 44px;
        margin-bottom: 14px;
        text-transform: uppercase;
    }

    &__text {
        @include font(24px, $font-primary--medium);
        color: $color-thirdly-black;
        line-height: 36px;
        width: 100%;
    }

    &:not(&:last-of-type) {
        min-height: 506px;

        @media (max-width: 900px) {
            min-height: initial;
        }
    }

    &:last-of-type {
        #{$this}__leftSide,
        #{$this}__rightSide {
            padding-bottom: 0;
        }

        #{$this}__lineGradient {
            background-image: linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0));
            bottom: -20px;
            height: 100%;
            position: absolute;
            width: 10px;
        }
    }

    &.aos-fix {
        .step__image {
            position: fixed;
            top: 80px;
            left: unset;
        }
    }

    &.aos-unset {
        .step__image {
            position: unset;
            img {
                transition: none;
            }
        }
    }

    &.aos-animate {
        .step__index {
            &[data-aos="index-activation"] {
                background-color: $color-primary-black !important;
                color: #fff;
            }
        }
        .step__image img {
            opacity: 1;
            // animation: opacityOn 1.5s ease-in both;
        }
    }

    @media (max-width: 1208px) {
        &__leftSide {
            padding-right: 64px;
        }

        &__image {
            img {
                max-height: 204px;
            }
        }

        &--mobile {
            &__image {
                img {
                    max-height: 204px;
                }
            }
        }

        &__rightSide {
            padding-left: 64px;
        }

        &__title {
            font-size: 30px;
            line-height: 44px;
            margin-bottom: 16px;
        }

        &__text {
            @include font(20px, $font-primary--regular);
            line-height: 30px;
        }
    }

    @media (max-width: 900px) {
        &__leftSide {
            padding-right: 32px;
        }

        &__index {
            height: 32px;
            width: 32px;

            span {
                font-size: 16px;
                font-weight: 700;
            }
        }

        &__line {
            height: calc(100% - 39px);
        }

        &__rightSide {
            padding-left: 32px;
        }

        &__title {
            font-size: 24px;
            line-height: 28px;
        }
        
        &__text {
            font-size: 16px;
            line-height: 24px;
        }
    }

    @media (max-width: 767px) {
        &--mobile {
            &__image {
                display: block;
            }
        }

        &__index {
            height: 48px;
            width: 48px;

            span {
                font-size: 24px;
            }
        }

        &__leftSide {
            display: none;
        }

        &__lineWrapper {
            left: 0;
        }

        &__rightSide {
            padding-left: 64px;
            width: 100%;
        }

        &__title {
            font-size: 32px;
            line-height: 44px;
            margin-bottom: 16px;
        }

        &__text {
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 16px;
        }
    }

    @media (max-width: 460px) {
        &--mobile {
            &__image {
    
                img {
                    max-height: 183px;
                }
            }
        }

        &__index {
            height: 32px;
            width: 32px;

            span {
                font-size: 16px;
                font-weight: 700;
            }
        }

        &__rightSide {
            padding-left: 32px;
        }

        &__title {
            font-size: 16px;
            line-height: 24px;
        }
        
        &__text {
            font-size: 14px;
            line-height: 21px;
        }
    }
}

@keyframes opacityOn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
