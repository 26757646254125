.section {
    $this: &;
    &-investing-in-digital {
        background-color: $color-slide-technology;
        display: flex;
        position: relative;
        justify-content: space-between;
        padding: 140px calc((100% - 1128px) / 2) 128px;

        #{$this}__anchor {
            position: absolute;
            top: -20px;

            @include on-safari {
                top: -60px;
            }
        }

        #{$this}__left-side {
            width: 57%;
        }

        #{$this}__title-wrapper {
            h1 {
                margin-top: 0;
                margin-bottom: 24px;
            }
        }

        #{$this}__title {
            @include font(48px, $font-primary--extraBold);
            // color: $color-primary-black;
            color: white;
            line-height: 64px;
        }

        #{$this}__paragraph-wrapper {
            .paragraph {
                @include font(24px, $font-primary--medium);
                // color: $color-paragraph-primary;
                color: white;
                line-height: 36px;
                &:first-child {
                    margin-bottom: 2em;
                }
            }
        }

        #{$this}__right-side {
            display: flex;
            align-items: flex-start;
            margin-left: 50px;
            #{$this}__video {
                display: block;
            }
        }

        #{$this}__video {
            display: none;
            margin-top: 19px;

            video {
                object-fit: contain;
                width: 410px;
                height: 410px;
                border-radius: 50%;
            }
        }

        a {
            outline: none;
        }

        @media (max-width: 1300px) {
            #{$this}__video video {
                width: 400px;
                height: 400px;
            }
        }

        @media (max-width: 1250px) {
            padding: 80px 40px 120px;
            #{$this}__title-wrapper {
                text-align: center;
                max-width: 552px;
            }
            #{$this}__video {
                display: block;
                margin-bottom: 20px;
            }
            #{$this}__paragraph-wrapper {
                line-height: 24px;
                max-width: 552px;
                .paragraph {
                    text-align: center;
                    &:first-child {
                        margin-bottom: 1em;
                    }
                }
            }

            #{$this}__left-side {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
            }
            #{$this}__right-side {
                display: none;
            }
        }

        @media (max-width: 1050px) {
            #{$this}__video video {
                width: 382px;
                height: 382px;
            }
        }

        @media (max-width: 900px) {
            padding-top: 60px;
            #{$this}__title-wrapper {
                .section__title {
                    line-height: 56px;
                }
            }
        }

        @media (max-width: 768px) {
            #{$this}__video video {
                width: 276px;
                height: 276px;
            }

            #{$this}__anchor {
                position: absolute;
                top: -35px;
            }

            #{$this}__title-wrapper {
                h1 {
                    margin-bottom: 16px;
                }
            }

            #{$this}__paragraph-wrapper {
                .paragraph {
                    @include font(16, $font-primary--medium);
                    line-height: 28px;
                }
            }
        }

        @media (max-width: 650px) {
            #{$this}__title-wrapper {
                .section__title {
                    line-height: 40px;
                }
            }
        }

        @media (max-width: 430px) {
            padding: 40px 16px 110px;
        }
    }
}